import { React, View, Text, Page, onMount } from 'lib'
import { Theme } from 'app'

function Hello(props) {
  onMount(() => {
    window.location.href = '/guidance/key-contacts'
  })
  return (
    <Page id={'help'} center={true}>
      <View style={styles.wrapper}>
        <Text variant={`h3`} text={`Redirecting...`}/>
      </View>
    </Page>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
  },
}

export default Hello
